import { useTranslation } from 'react-i18next';
import { getPassengersClass, getPassengersCount } from 'src/store/search';

export const getPassengersMainInfo = () => {
    const { t } = useTranslation();
    const passengersCount = getPassengersCount();
    const transferClass = getPassengersClass();

    return `${t('shared.countOfPassengers.passenger', { count: passengersCount })}. ${t(
        `shared.cabin.${transferClass}`,
    )}`;
};
