import { GetTicketsInput } from 'src/api';
import { SearchData } from 'src/types';

export const convertSearchData = (searchData: SearchData): GetTicketsInput => ({
    adults_count: searchData.adultsCount,
    children_count: searchData.childrenCount,
    infants_count: searchData.infantsCount,
    date_from: searchData.dateFrom.toISOString(),
    from: searchData.from.code,
    to: searchData.to?.code,
    only_economic: searchData.transferClass === 'economy' ? 1 : 0,
    direct_only: searchData.withoutTransfer ? 1 : 0,
    date_to: searchData.withReturnTicket ? searchData.dateTo?.toISOString() : null,
});
