import { FC, ReactNode, startTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import { QuestionMark } from 'src/assets/icons';
import { QueryParam, Route } from 'src/constants';
import { FaqType } from 'src/types';
import { getHeaderInfo } from 'src/utils';

import { ArrowBack } from '../back-button';

import styles from './index.module.scss';

type Props = {
    children?: ReactNode | string;
    title?: ReactNode | string;
    className?: string;
    rightAddon?: ReactNode;
    isExternalFaqShow?: boolean;
    onGoBack?: () => void;
};

export const Header: FC<Props> = ({
    children,
    title,
    className,
    rightAddon,
    isExternalFaqShow = false,
    onGoBack,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { headerTitle, isShowenFAQ } = getHeaderInfo();

    const handleFaqClick = () => {
        startTransition(() => navigate(`${Route.FAQ}?${QueryParam.FAQ_TYPE}=${FaqType.MAIN}`));
    };

    return (
        <header className={clsx(styles.header, className)}>
            <div className={styles.header_inner}>
                <div className={styles.arrow_back}>
                    <ArrowBack onGoBack={onGoBack} />
                </div>
                {Boolean(title || headerTitle) && <div className={styles.title}>{title || headerTitle}</div>}
                <div className={clsx(styles.right_addon, !title && styles.right_addon__wide)}>
                    {rightAddon && rightAddon}
                </div>
                {(isShowenFAQ || isExternalFaqShow) && (
                    <button onClick={handleFaqClick} className={styles.faq_button}>
                        {location.pathname === Route.MAIN ? (
                            <span>{t('pages.main.banner.support')}</span>
                        ) : (
                            <img src={QuestionMark} alt='question mark' />
                        )}
                    </button>
                )}
            </div>
            {children}
        </header>
    );
};
