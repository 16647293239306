import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/market-ui/button';
import { Modal } from 'src/components/modal';
import { closeWebView } from 'src/utils';

import styles from './index.module.scss';

const PageError = () => {
	const { t } = useTranslation();

	const onClick = () => {
		closeWebView();
	};

	return (
    <Modal
        className={styles.modal}
        position="center"
        width={280}
        height={140}
        header={t('shared.error.somethingWentWrong')}
        isOpen={true}
        footer={
            <Button onClick={onClick} className={styles.back_button}>
                {t('shared.error.backToMain')}
            </Button>
			}
		>
        <div></div>
    </Modal>
	);
};

export default PageError;
