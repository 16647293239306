import { Moment } from 'moment';

export enum Direction {
    TO = 'to',
    FROM = 'from',
}

export type Distination = {
    name: string;
    code: string;
};

export type TransferClass = 'economy' | 'business';

export type Details = {
    adultsCount: number;
    childrenCount: number;
    infantsCount: number;
    transferClass: TransferClass;
    isFilled: boolean;
};

export enum PassengerType {
    ChildrenCount = 'childrenCount',
    InfantsCount = 'infantsCount',
    AdultsCount = 'adultsCount',
}

export type DetailsForm = {
    [key: string]: any;
};

export type Dates = {
    dateTo?: Moment;
    dateFrom: Moment;
};

export type Directions = {
    [key in Direction]?: Distination;
};

export type ExtraOptions = {
    withReturnTicket: boolean;
    withoutTransfer: boolean;
};

export type SearchData = Details & Dates & Required<Directions> & ExtraOptions;
