import moment from 'moment';

export const getYearsList = () => {
    const currentDate = moment();
    const years = [];

    for (let i = 0; i < 20; i++) {
        years.push(currentDate.clone().format('YYYY'));
        currentDate.subtract(1, 'years');
    }

    return years.reverse();
};
