import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import App from 'src/app';

import en from './locale/en.json';
import kg from './locale/kg.json';
import ru from './locale/ru.json';

import './styles/index.scss';

import './polyfills';

i18next
    .use(initReactI18next)
    .use(intervalPlural)
    .init(
        {
            resources: {
                ru: { translation: { ...ru } },
                ky: { translation: { ...kg } },
                en: { translation: { ...en } },
            },
            lng: 'ru',
            debug: true,
        },
        (err, t) => {
            if (err) return console.log('Translation: Something went wrong loading', err);
            t('key');
        },
    );

const rootElement = document?.getElementById('root');

if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);

    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
    );
}
