import React, { useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { Outlet, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Header } from 'src/components/header';
import { Loader } from 'src/components/loader';
import { Button } from 'src/components/market-ui/button';
import { useOnInputFocusViewportChange } from 'src/hooks';
import { useSetOnKeyboardHandler } from 'src/hooks/use-set-on-keyboard-handler';

import { getLayoutState } from './lib/context';

import styles from './index.module.scss';

export const Layout: React.FC = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollableContainerRef = useRef<HTMLDivElement>(null);
    const { header, button, bottomAddon, content } = getLayoutState();
    const [isBottomShowed, setIsBottomShowed] = useState(true);
    const { pathname } = useLocation();

    useOnInputFocusViewportChange(wrapperRef.current, true, setIsBottomShowed);
    useSetOnKeyboardHandler(setIsBottomShowed);

    useEffect(() => {
        if (scrollableContainerRef.current) {
            setTimeout(() => {
                scrollableContainerRef.current.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 150);
        }
    }, [pathname]);

    return (
        <div
            className={clsx(styles.wrapper, isIOS && styles.wrapper__static_height)}
            ref={wrapperRef}
        >
            <Header
                rightAddon={header?.rightAddon}
                title={header?.title}
                onGoBack={header?.goBackClickHandler}
            />
            <div
                className={clsx(
                    styles.scrollable_container,
                    content?.isFullHeight && styles.scrollable_container__fixed,
                )}
                ref={scrollableContainerRef}
            >
                <div
                    className={clsx(
                        styles.content,
                        content?.withoutPaddings && styles.content__no_paddings,
                        content?.isFullHeight && styles.content__ful_height,
                    )}
                >
                    <Outlet />
                </div>
            </div>
            {isBottomShowed && Boolean(button?.text) && (
                <div className={styles.bottom_container}>
                    {bottomAddon}
                    <Button
                        isLoading={button.loading}
                        disabled={button.disabled}
                        onClick={button.handler}
                        hidden={button.hidden}
                    >
                        {button.loading ? <Loader width={28} height={28} /> : button.text}
                    </Button>
                </div>
            )}
        </div>
    );
};
