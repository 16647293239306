export const BaseUrl = {
    TICKETS: process.env.REACT_APP_TICKETS_URL,
    CLIENT: process.env.REACT_APP_CLIENT_URL,
};

export enum TicketsUri {
    AUTHORIZATION = '/auth/login',
    CITIES = '/cities',
    TICKETS = '/tickets',
    CALENDAR_PRICE = '/tickets/calendar-price',
    PREBOOKING = '/booking/pre-booking',
    BOOKING = '/booking',
}

export enum PassengersUri {
    GET = '/passengers',
    UPDATE = '/passengers/update',
    DELETE = '/passengers/remove',
    CREATE = '/passengers/create',
}

export enum OrdersUri {
    GET_ACTIVE = '/orders/active',
    GET_BOOKED = '/orders/booked',
    GET_CONFIRMED = '/orders',
}

export enum DictionariesUri {
    GET_COUNTRIES = '/dictionaries/countries',
    GET_DOCUMENT_TYPES = '/dictionaries/document-types',
}

export enum ClientUri {
    CLIENT = '/getClientData',
}

export const PHONE_PREFIX = '996';
