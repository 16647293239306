import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { ErrorHandling } from 'src/components/error-handling';
import { Route } from 'src/constants';
import { Param } from 'src/constants/routing';
import { Layout } from 'src/layout';
import { TicketView } from 'src/pages/ticket-view';

const MainPage = lazy(async () => {
    return import('src/pages/main-page').then(({ MainPage }) => ({
        default: MainPage,
    }));
});

const TicketsSearchPage = lazy(async () => {
    return import('src/pages/tickets-search-page').then(({ TicketsSearchPage }) => ({
        default: TicketsSearchPage,
    }));
});
const TicketDetailsPage = lazy(async () => {
    return import('src/pages/ticket-details-page').then(({ TicketDetailsPage }) => ({
        default: TicketDetailsPage,
    }));
});

const FaqPage = lazy(async () => {
    return import('src/pages/faq-page').then(({ FaqPage }) => ({
        default: FaqPage,
    }));
});

const ProfileMain = lazy(async () => {
    return import('src/pages/profile-page/pages/main').then(({ ProfileMain }) => ({
        default: ProfileMain,
    }));
});

const History = lazy(async () => {
    return import('src/pages/profile-page/pages/history').then(({ History }) => ({
        default: History,
    }));
});

const Passangers = lazy(async () => {
    return import('src/pages/profile-page/pages/passangers').then(({ Passangers }) => ({
        default: Passangers,
    }));
});

const PassangersForm = lazy(async () => {
    return import('src/pages/profile-page/pages/passangers-form').then(({ PassangersForm }) => ({
        default: PassangersForm,
    }));
});

const SearchMain = lazy(async () => {
    return import('src/pages/search-page/pages/main').then(({ SearchMain }) => ({
        default: SearchMain,
    }));
});

const Direction = lazy(async () => {
    return import('src/pages/search-page/pages/direction').then(({ DirectionPage }) => ({
        default: DirectionPage,
    }));
});

const Dates = lazy(async () => {
    return import('src/pages/search-page/pages/dates').then(({ DatesPage }) => ({
        default: DatesPage,
    }));
});

const Details = lazy(async () => {
    return import('src/pages/search-page/pages/details').then(({ DetailsPage }) => ({
        default: DetailsPage,
    }));
});

const PrebookingMain = lazy(async () => {
    return import('src/pages/prebooking/pages/main').then(({ PrebookingMain }) => ({
        default: PrebookingMain,
    }));
});

const Passenger = lazy(async () => {
    return import('src/pages/prebooking/pages/passenger').then(({ Passenger }) => ({
        default: Passenger,
    }));
});

const BookingMain = lazy(async () => {
    return import('src/pages/booking/pages/main').then(({ BookingPage }) => ({
        default: BookingPage,
    }));
});

const BookingPassenger = lazy(async () => {
    return import('src/pages/booking/pages/passenger').then(({ BookingPassenger }) => ({
        default: BookingPassenger,
    }));
});

const ConfirmationPage = lazy(async () => {
    return import('src/pages/confirmation').then(({ ConfirmationPage }) => ({
        default: ConfirmationPage,
    }));
});

const ConfirmationAddPage = lazy(async () => {
    return import('src/pages/confirmation-add').then(({ ConfirmationAddPage }) => ({
        default: ConfirmationAddPage,
    }));
});

export const router = createBrowserRouter([
    {
        path: Route.MAIN,
        element: <ErrorHandling />,
        children: [
            {
                index: true,
                element: <MainPage />,
            },
            {
                path: Route.MAIN,
                element: <Layout />,
                children: [
                    {
                        path: Route.TICKETS,
                        element: <TicketsSearchPage />,
                    },
                    {
                        path: `${Route.TICKET_DETAILS}/:${Param.TICKET_ID}`,
                        element: <TicketDetailsPage />,
                    },
                    {
                        path: Route.FAQ,
                        element: <FaqPage />,
                    },
                    { path: Route.PROFILE, element: <ProfileMain /> },
                    { path: Route.HISTORY, element: <History /> },
                    { path: Route.PASSENGERS, element: <Passangers /> },
                    {
                        path: `${Route.PASSENGERS_FORM_VIEW}/:${Param.PASSENGER_ID}`,
                        element: <PassangersForm />,
                    },
                    {
                        path: Route.PASSENGERS_FORM_ADD,
                        element: <PassangersForm />,
                    },
                    { path: Route.SEARCH, element: <SearchMain /> },
                    { path: Route.SEARCH_DIRECTION, element: <Direction /> },
                    { path: Route.SEARCH_DATES, element: <Dates /> },
                    { path: Route.SEARCH_DETAILS, element: <Details /> },
                    {
                        path: `${Route.PREBOOKING}/:${Param.TICKET_ID}`,
                        element: <PrebookingMain />,
                    },
                    {
                        path: `${Route.PREBOOKING}/:${Param.TICKET_ID}${Route.PREBOOKING_PASSENGER}/:${Param.PASSENGER_ID}`,
                        element: <Passenger />,
                    },
                    { path: Route.BOOKING, element: <BookingMain /> },
                    {
                        path: `${Route.BOOKING_PASSENGER}/:${Param.DOC_NUMBER}`,
                        element: <BookingPassenger />,
                    },
                    { path: Route.BOOKED_TICKET, element: <BookingMain /> },
                    { path: Route.ACTIVE_TICKET, element: <BookingMain /> },
                    {
                        path: `${Route.TICKET_VIEW}/:${Param.ORDER_NUMBER}`,
                        element: <TicketView />,
                    },
                    {
                        path: Route.CONFIRMATION,
                        element: <ConfirmationPage />,
                    },
                    {
                        path: Route.CONFIRMATION_ADD,
                        element: <ConfirmationAddPage />,
                    },
                ],
            },
        ],
    },
]);
