import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BaseUrl } from 'src/api/constants';
// import { Download } from 'src/assets/icons';
import { PdfView } from 'src/components/pdf-view';
import { Param } from 'src/constants';
import { useSetLayoutState } from 'src/layout/lib/context';

export const TicketView: FC = () => {
    const params = useParams();
    const order_number = params[Param.ORDER_NUMBER];
    const pdfUrl = `${BaseUrl.TICKETS}/file/${order_number}`;
    const { t } = useTranslation();

    const setLayoutState = useSetLayoutState();

    // todo - расскоментировать после исправлений на мобилке
    // const downloadButton = (
    //     <a href={pdfUrl} download={true}>
    //         <img src={Download} alt='download icon' />
    //     </a>
    // );

    setLayoutState({
        header: {
            title: t('pages.ticketView.title'),
            // todo - расскоментировать после исправлений на мобилке
            // rightAddon: downloadButton,
        },
    });

    return (
        <div>
            <PdfView url={pdfUrl} />
        </div>
    );
};
