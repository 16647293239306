import { FlightType } from 'src/types';

export const iconUrl = 'https://minio.o.kg/catalog/airlines';

export const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;

export const PREBOOKING_TIMER_ID = 'prebooking_timer_id';

export const DEFAULT_DEPARTURE_CODE = 'FRU';

export const MAX_PASSENGER_VALUE = 8;

export const INTERNAL_WAY_CODE = ['FRU', 'OSS', 'ДЖБ', 'IKU', 'БАТ', 'ИФА'];

export const latinPattern = /^(?!-)(?=.*[a-zA-Z])[a-zA-Z\s\-]+$/;

export const AgeLimit = {
    [FlightType.INTERNAL_FLIGHT]: {
        MIN_INF_AGE: 0,
        MAX_INF_AGE: 4,
        MIN_CHD_AGE: 5,
        MAX_CHD_AGE: 12,
        MIN_ADT_AGE: 13,
        MAX_ADT_AGE: Infinity,
    },
    [FlightType.OUTER_FLIGHT]: {
        MIN_INF_AGE: 0,
        MAX_INF_AGE: 2,
        MIN_CHD_AGE: 3,
        MAX_CHD_AGE: 12,
        MIN_ADT_AGE: 13,
        MAX_ADT_AGE: Infinity,
    },
};
