import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { clsx } from 'clsx';

import styles from './index.module.scss';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    secondary?: boolean;
    isLoading?: boolean;
};

export const Button = ({ secondary = false, isLoading, hidden, ...rest }: Props) => (
    <button
        {...rest}
        className={clsx(
            styles.default,
            secondary && styles.secondary,
            rest.className,
            isLoading && styles.isLoading,
            hidden && styles.hidden,
        )}
    ></button>
);
