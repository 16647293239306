import moment from 'moment';

export const getYearMonths = (year: number) => {
    const startDate = moment([year, 0]);

    const months = [];

    while (startDate.year() === year) {
        months.push(startDate.clone().format('MMM'));
        startDate.add(1, 'months');
    }

    return months;
};
