import { create } from 'zustand';

export type Theme = 'light' | 'dark';

/**
 * Represents the error codes used in the application.
 * Possible values are '0000', '0001', and '0002'.
 * '0000' - indent error code
 * '0001' - error code for 401, 500
 * '0002' - error code for 400, 600
 * '0003' - timer is out
 */
export type ErrorCode = '0000' | '0001' | '0002' | '0003';

export type Error = {
    code: ErrorCode;
    message?: string;
};

type Locale = 'ru' | 'en' | 'ky';

type AppState = {
    error: Error;
    locale: Locale;
    theme: Theme;
    isInit: boolean;
};

type AppCallbacksStore = {
    setError: (error: Error) => void;
    setLocale: (locale: Locale) => void;
    setTheme: (theme: Theme) => void;
    setIsInit: () => void;
};

const initialState: AppState = {
    error: {
        code: null,
        message: null,
    },
    locale: 'ru',
    theme: 'light',
    isInit: false,
};

export const AppStore = create<AppState & AppCallbacksStore>((set) => ({
    ...initialState,
    setError: (error) => set((state) => ({ ...state, error })),
    setLocale: (locale) => set((state) => ({ ...state, locale })),
    setTheme: (theme) => set((state) => ({ ...state, theme })),
    setIsInit: () => set((state) => ({ ...state, isInit: true })),
}));

export const useSetError = () => AppStore((state) => state.setError);
export const getError = () => AppStore((state) => state.error);

export const useSetLocale = () => AppStore((state) => state.setLocale);
export const getLocale = () => AppStore((state) => state.locale);

export const useSetTheme = () => AppStore((state) => state.setTheme);
export const useSetIsInit = () => AppStore((state) => state.setIsInit);
export const getIsInit = () => AppStore((state) => state.isInit);
export const getTheme = () => AppStore((state) => state.theme);
export const getIsLightTheme = () => AppStore((state) => state.theme === 'light');
