import { useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';

import { IPdfViewProps } from './types';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const DEFAULT_WIDTH = 390;

export const PdfView = ({ url }: IPdfViewProps) => {
    const [numPages, setNumPages] = useState<number | null>(null);

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy): void => {
        setNumPages(nextNumPages);
    };

    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const [pdfWidth, setPdfWidth] = useState(DEFAULT_WIDTH);

    useEffect(() => {
        if (wrapperRef.current?.offsetWidth) setPdfWidth(wrapperRef.current.offsetWidth);
        pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    }, []);

    const documentProps = useMemo(
        () => ({
            file: url,
            options: {
                withCredentials: true,
            },
        }),
        [url],
    );

    return (
        <div ref={wrapperRef} className='pdf-container'>
            <Document
                onLoadSuccess={onDocumentLoadSuccess}
                externalLinkTarget='_blank'
                loading={<div>Loading...</div>}
                {...documentProps}
            >
                {Array.apply(null, Array(numPages))
                    .map((_, i) => i + 1)
                    .map((page) => (
                        <Page
                            loading={null}
                            pageNumber={page}
                            width={pdfWidth}
                            renderTextLayer={true}
                            renderAnnotationLayer={true}
                            key={`page_${page}`}
                        />
                    ))}
            </Document>
        </div>
    );
};
