import axios from 'axios';
import { BaseUrl } from 'src/api/constants';

export const ticketsInstance = axios.create({
    baseURL: BaseUrl.TICKETS,
    withCredentials: true,
});

export const clientInstance = axios.create({
    baseURL: BaseUrl.CLIENT,
});
