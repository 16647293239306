import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBack as ArrowBackIcon } from 'src/assets/icons';
import { Route } from 'src/constants';
import { closeWebView } from 'src/utils';

import styles from './index.module.scss';

type Props = {
    onGoBack?: () => void;
};

export const ArrowBack: FC<Props> = ({ onGoBack }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleGoBack = () => {
        switch (pathname) {
            case Route.MAIN:
                closeWebView();
                break;

            case Route.SEARCH_DETAILS:
                navigate(Route.MAIN);
                break;

            case Route.SEARCH_DIRECTION:
                navigate(Route.MAIN);
                break;

            case Route.SEARCH_DATES:
                navigate(Route.MAIN);
                break;

            case Route.TICKETS:
                navigate(Route.MAIN);
                break;

            case Route.TICKET_DETAILS:
                navigate(Route.TICKETS);
                break;

            case Route.PREBOOKING:
                navigate(Route.TICKET_DETAILS);
                break;

            case Route.PREBOOKING_PASSENGER:
                navigate(Route.PREBOOKING);
                break;

            case Route.BOOKING:
                navigate(Route.TICKETS);
                break;

            case Route.PROFILE:
                navigate(Route.MAIN);
                break;

            case Route.PASSENGERS:
                navigate(Route.PROFILE);
                break;

            case Route.PASSENGERS_FORM_VIEW:
                navigate(Route.PASSENGERS);
                break;

            case Route.PASSENGERS_FORM_ADD:
                navigate(Route.PASSENGERS);
                break;

            default:
                navigate(-1);
                break;
        }
    };

    return (
        <button className={styles.button} onClick={onGoBack || handleGoBack}>
            <img
                width={24}
                height={24}
                src={ArrowBackIcon}
                alt='back'
            />
        </button>
    );
};
