export type GetClientInput = {
    token: string;
};

export enum WalletType {
    WALLET_TYPE_ANONIMOUS = 'WALLET_TYPE_ANONIMOUS',
    WALLET_TYPE_IDENTIFIED = 'WALLET_TYPE_IDENTIFIED',
    WALLET_TYPE_NAMED = 'WALLET_TYPE_NAMED',
    WALLET_TYPE_ENTREPRENEUR = 'WALLET_TYPE_ENTREPRENEUR',
    WALLET_TYPE_IDENTIFIED_ONLINE = 'WALLET_TYPE_IDENTIFIED_ONLINE',
    WALLET_TYPE_IDENTIFIED_VIDEO = 'WALLET_TYPE_IDENTIFIED_VIDEO',
}

type Client = {
    firstName: string;
    last_name: string;
    birthday: string;
    sex: string;
    passportNumber: string;
    passportEnd: string;
    isResident: boolean;
    inn: string;
    accountId: string;
    walletType: WalletType;
};

export type GetClientPayload = {
    date: string;
    data: {
        client: Client;
        msisdn: string;
    };
};
