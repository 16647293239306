import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import styles from './index.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
    header?: ReactNode;
    children: ReactNode;
    footer?: ReactNode;
    position?: 'center' | 'bottom';
    isOpen: boolean;
    onClose?: () => void;
    isShowCloseButton?: boolean;
};

export const Modal: FC<Props> = ({
    className,
    width,
    height,
    children,
    isOpen,
    onClose,
    header,
    footer,
    position = 'bottom',
    isShowCloseButton = false,
}) => {
    const modalRoot = document.getElementById('modal-root');
    const isShowenCloseButton = isShowCloseButton && Boolean(onClose);

    const handleOnClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const renderModalContent = () => (
        <>
            <div className={styles.backdrop}></div>
            <div
                style={{ width, minHeight: height }}
                className={clsx(styles.modal, className, styles[position])}
            >
                {(isShowenCloseButton || header) && (
                    <div className={styles.header}>
                        {isShowenCloseButton && (
                            <i onClick={handleOnClose} className={styles.close} />
                        )}
                        {header && <div className={styles.header_content}>{header}</div>}
                    </div>
                )}
                <div className={styles.content}>{children}</div>
                {footer && <div className={styles.footer}>{footer}</div>}
            </div>
        </>
    );

    return isOpen && createPortal(renderModalContent(), modalRoot);
};
