import React, { Component, ErrorInfo, ReactNode, Suspense } from 'react';
import PageError from 'src/components/page-error';

type ErrorBoundaryProps = {
	children: ReactNode;
};

type ErrorBoundaryState = {
	hasError: boolean;
};

class ErrorBoundary extends Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {

	state: ErrorBoundaryState = {
		hasError: false,
	};

	static getDerivedStateFromError(): ErrorBoundaryState {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.log({ error, errorInfo });
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (hasError) {
			return (
    <Suspense fallback=''>
        <PageError />
    </Suspense>
			);
		}

		return children;
	}
}

export default ErrorBoundary;
