import { useMemo } from 'react';
import { getIsOneWayTicket, useSearchData } from 'src/store/search';
import { checkDatesFill, checkDirectionsFill, convertSearchData } from 'src/utils';

export const useConvertedSearchData = () => {
    const { dates, directions, extraOptions, details } = useSearchData();
    const isOneWayTicket = getIsOneWayTicket();

    return useMemo(() => {
        const oneWayRelatedDates = isOneWayTicket ? { ...dates, dateTo: null } : dates;

        if (
            checkDirectionsFill(directions) &&
            checkDatesFill(dates, extraOptions.withReturnTicket)
        ) {
            return convertSearchData({
                ...details,
                ...oneWayRelatedDates,
                ...extraOptions,
                to: directions.to,
                from: directions.from,
            });
        }

        return;
    }, [dates, directions, extraOptions, details, isOneWayTicket]);
};
