const KEYBOARD_HEIGHT = 150;

export const checkIsKeyboardShowed = () => {
    const viewportHeight = window.innerHeight;
    const documentStyles = getComputedStyle(document.documentElement);
    const initialViewportHeight = documentStyles.getPropertyValue('--full-height');

    const heightDifference = parseInt(initialViewportHeight) - viewportHeight;

    return heightDifference > KEYBOARD_HEIGHT;
};
