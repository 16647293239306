import { AgeLimit } from 'src/constants';
import { AgeType, DocumentType, FlightType } from 'src/types';
import { FlightTypes } from 'src/types/prebooking';

const DOCUMENT_NUMBER_PATTERN = /^[A-Za-z]{2}\d{7}$/;

const internalLimits = AgeLimit[FlightType.INTERNAL_FLIGHT];
const outerLimits = AgeLimit[FlightType.OUTER_FLIGHT];

const FLIGHT_TYPES: FlightTypes = {
    [FlightType.INTERNAL_FLIGHT]: {
        CHD: {
            from: internalLimits.MIN_CHD_AGE,
            to: internalLimits.MAX_CHD_AGE,
        },
        INF: {
            from: internalLimits.MIN_INF_AGE,
            to: internalLimits.MAX_INF_AGE,
        },
        ADT: {
            from: internalLimits.MIN_ADT_AGE,
            to: internalLimits.MAX_ADT_AGE,
        },
    },
    [FlightType.OUTER_FLIGHT]: {
        CHD: {
            from: outerLimits.MIN_CHD_AGE,
            to: outerLimits.MAX_CHD_AGE,
        },
        INF: {
            from: outerLimits.MIN_INF_AGE,
            to: outerLimits.MAX_INF_AGE,
        },
        ADT: {
            from: outerLimits.MIN_ADT_AGE,
            to: outerLimits.MAX_ADT_AGE,
        },
    },
};

export const ageValidation = (age: number, ageType: AgeType, flightType: FlightType): boolean => {
    const { from, to } = FLIGHT_TYPES[flightType][ageType];

    return age >= from && age <= to;
};

export const docNumberValidation = (docType: DocumentType, value: string): boolean => {
    if (docType === DocumentType.NP || docType === DocumentType.PSP)
        return DOCUMENT_NUMBER_PATTERN.test(value);

    return true;
};
