import moment from 'moment';

export const getMonthDays = (year: number, month: number) => {
    const startDate = moment([year, month]);

    const dates = [];

    while (startDate.month() === month) {
        dates.push(startDate.clone().format('DD'));
        startDate.add(1, 'days');
    }

    return dates;
};
