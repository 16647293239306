import { queryClient } from 'src/app';
import { SearchCallbacksStore, SearchStateStore } from 'src/types';
import { getFlightDirection } from 'src/utils';
import { create } from 'zustand';

const initialState: SearchStateStore = {
    details: {
        adultsCount: 1,
        childrenCount: 0,
        infantsCount: 0,
        transferClass: 'economy',
        isFilled: false,
    },
    dates: {
        dateFrom: null,
        dateTo: null,
    },
    directions: {
        from: null,
        to: null,
    },
    defaultDirection: null,
    extraOptions: {
        withReturnTicket: false,
        withoutTransfer: false,
    },
    isExternalFlight: false,
};

export const searchStore = create<SearchStateStore & SearchCallbacksStore>()((set) => ({
    ...initialState,
    setDetails: (details) => set((state) => ({ details: { ...state.details, ...details } })),
    setDates: (dates) => set((state) => ({ dates: { ...state.dates, ...dates } })),
    setDirections: (directions) =>
        set((state) => {
            const { isDirectionInternal } = getFlightDirection(directions);

            return {
                directions: { ...state.directions, ...directions },
                isExternalFlight: !isDirectionInternal,
            };
        }),
    setDefaultDirection: (direction) =>
        set((state) => ({
            defaultDirection: direction,
            directions: { ...state.directions, from: direction },
        })),
    setExtraOptions: (extraOptions) =>
        set((state) => ({ extraOptions: { ...state.extraOptions, ...extraOptions } })),
    switchDirections: () =>
        set((state) => ({
            directions: { to: state.directions?.from, from: state.directions?.to },
        })),
}));

export const useDetails = () => searchStore((state) => state.details);
export const getDates = () => searchStore((state) => state.dates);
export const getDefaultDirection = () => searchStore((state) => state.defaultDirection);
export const getIsExternalFlight = () => searchStore((state) => state.isExternalFlight);
export const useDirections = () => searchStore((state) => state.directions);
export const useExtraOptions = () => searchStore((state) => state.extraOptions);
export const useSearchData = () =>
    searchStore((state) => ({
        details: state.details,
        dates: state.dates,
        directions: state.directions,
        extraOptions: state.extraOptions,
    }));

export const useSetPassengers = () => searchStore((state) => state.setDetails);
export const useSetDates = () => searchStore((state) => state.setDates);
export const useSetDirections = () => searchStore((state) => state.setDirections);
export const getSetDirections = () => searchStore((state) => state.directions);
export const useSetExtraOptions = () => searchStore((state) => state.setExtraOptions);
export const useSwitchDirections = () => searchStore((state) => state.switchDirections);
export const useSetDefaultDirection = () => searchStore((state) => state.setDefaultDirection);

export const getPassengersClass = () => searchStore((state) => state.details.transferClass);
export const getPassengersCount = () =>
    searchStore((state) => {
        const { infantsCount, adultsCount, childrenCount } = state.details;

        return infantsCount + adultsCount + childrenCount;
    });
export const getIsOneWayTicket = () => searchStore((state) => !state.extraOptions.withReturnTicket);

searchStore.subscribe(() => {
    queryClient.invalidateQueries({
        queryKey: ['getTickets'],
        refetchType: 'none',
    });
});
