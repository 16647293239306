import { Dictionary, SelectOption } from 'src/types';

export const convertPassengersOptions = (data: Dictionary[]): SelectOption[] => {
    if (data) {
        return data.map(({ code, name }) => ({
            id: code,
            value: name,
        }));
    }

    return [];
};
