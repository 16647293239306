import moment from 'moment';
export const secondToTimeFormat = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    return { hours, minutes };
};

export const secondsToDaysHoursMinutesAndSeconds = (seconds: number) => {
    const duration = moment.duration(seconds, 'seconds');
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours()) % 24;
    const minutes = Math.floor(duration.asMinutes()) % 60;
    const remainingSeconds = duration.seconds();

    return { days, hours, minutes, remainingSeconds };
};

export const millisecondToTimeFormat = (milliseconds: number) => {
    const hours = Math.floor(milliseconds / 1000 / 3600);
    const minutes = Math.floor(((milliseconds / 1000) % 3600) / 60);

    return { hours, minutes };
};
