import { QueryParam, Route } from 'src/constants';
import { useSearchData } from 'src/store/search';
import { Direction } from 'src/types';
import { checkDatesFill } from 'src/utils';

const STANDARD_FLOW = [
    Route.SEARCH_DIRECTION,
    Route.SEARCH_DATES,
    Route.SEARCH_DETAILS,
    Route.TICKETS,
] as const;

type FlowScreen = (typeof STANDARD_FLOW)[number];

export const useGetNextPresearchStep = (currentScreen?: FlowScreen) => {
    const { details, dates, directions, extraOptions } = useSearchData();

    const direction = directions.from?.code ? Direction.TO : Direction.FROM;

    const checkStateEmptiness = (screen: FlowScreen) => {
        if (screen === Route.SEARCH_DIRECTION)
            return !Boolean(directions?.from) || !Boolean(directions?.to);
        if (screen === Route.SEARCH_DATES) return !checkDatesFill(dates, extraOptions.withReturnTicket);
        if (screen === Route.SEARCH_DETAILS) return !details.isFilled;
    };

    for (const route of STANDARD_FLOW) {
        if (currentScreen && route === currentScreen) continue;
        if (checkStateEmptiness(route)) {
            return route === Route.SEARCH_DIRECTION
                ? `${Route.SEARCH_DIRECTION}?${QueryParam.DIRECTION}=${direction}`
                : route;
        }
    }

    return Route.TICKETS;
};
