import { useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { getIosOnKeyboardViewport } from 'src/utils';

const preventScrolling = (evt: Event) => {
    evt.preventDefault();
    window.scrollTo(0, 0);
};

const setWithKeyboardViewportHeight = (element: HTMLElement) => {
    const targetViewport = getIosOnKeyboardViewport();
    const body = document.querySelector('body');

    // todo: setTimeout нужен для того, чтобы обеспечить корректность работы
    // клика по интерактивным элементам, изначально находящимся под всплывающей внизу зоны для клавиатуры.
    // противном случае клик по интерактивным элементам не срабатывает с первого раза
    // при возможности узнать другие способы решить данную проблему
    setTimeout(() => {
        body.style.setProperty('overflow', 'hidden');

        element.style.setProperty('height', `${targetViewport}px`);
    }, 100);
};

const setInitialViewportHeight = (element: HTMLElement) => {
    const documentStyles = getComputedStyle(document.documentElement);
    const initialViewportHeight = documentStyles.getPropertyValue('--full-height');
    const body = document.querySelector('body');

    // todo: setTimeout нужен для того, чтобы обеспечить корректность работы
    // клика по интерактивным элементам, изначально находящимся под всплывающей внизу зоны для клавиатуры.
    // противном случае клик по интерактивным элементам не срабатывает с первого раза
    // при возможности узнать другие способы решить данную проблему
    setTimeout(() => {
        body.style.setProperty('overflow', 'initial');

        element.style.setProperty('height', initialViewportHeight);
    }, 100);
};

export const useOnInputFocusViewportChange = (
    element: HTMLElement,
    isEnabled: boolean,
    cb: (isKeyboardHidden: boolean) => void,
) => {
    const { pathname } = useLocation();
    const handleKeyboardHide = () => {
        cb(true);
        setInitialViewportHeight(element);
    };

    const handleKeyboardShow = (evt: FocusEvent) => {
        if (!(evt.target instanceof HTMLInputElement)) return;
        const inputType = evt.target.type;

        if (inputType === 'text' || inputType === 'number') {
            cb(false);
            setWithKeyboardViewportHeight(element);
        }
    };

    useEffect(() => {
        if (!element || !isEnabled || !isIOS) return;
        handleKeyboardHide();
        window?.addEventListener('focusin', handleKeyboardShow);
        window?.addEventListener('focusout', handleKeyboardHide);
        window?.addEventListener('scroll', preventScrolling);

        return () => {
            handleKeyboardHide();
            window?.removeEventListener('focusin', handleKeyboardShow);
            window?.removeEventListener('focusout', handleKeyboardHide);
            window?.removeEventListener('scroll', preventScrolling);
        };
    }, [pathname, element, isEnabled]);
};
