import { Suspense, useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import i18next from 'i18next';
import moment from 'moment';
import { CoverLoader } from 'src/components/cover-loader';
import { useSetFullHeightVariable } from 'src/hooks';
import ErrorBoundary from 'src/providers/error-boundary';
import { router } from 'src/routes';

import { getAppLocaleTheme } from './api/app';
import { getLocale, getTheme } from './store/app';

import 'moment/locale/ru';
import 'moment/locale/ky';

export const queryClient = new QueryClient();
queryClient.setDefaultOptions({
    queries: {
        refetchOnWindowFocus: false,
    },
});

moment.updateLocale('ky', {
    // переопределяем язык, т.к. по требованиям сокращения дней недель должны быть такие
    weekdaysMin: 'Жш_Дш_Шш_Шр_Бш_Жм_Иш'.split('_'),
});

function App() {
    getAppLocaleTheme();
    useSetFullHeightVariable();

    const locale = getLocale();
    const theme = getTheme();

    moment.locale(locale);

    i18next.changeLanguage(locale, (err, t) => {
        if (err) return console.log('Translation: something went wrong loading', err);
        t('key');
    });

    useEffect(() => {
        document.body.classList.remove('light', 'dark');
        document.body.classList.remove('ios', 'android');
        document.body.classList.add(theme);
        document.body.classList.add(isIOS ? 'ios' : 'android');
    }, [theme]);

    return (
        <ErrorBoundary>
            <Suspense fallback={null}>
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <RouterProvider router={router} fallbackElement={<CoverLoader />} />
                </QueryClientProvider>
            </Suspense>
        </ErrorBoundary>
    );
}

export default App;
