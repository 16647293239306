import { useSetLocale, useSetTheme } from 'src/store/app';

export const getAppLocaleTheme = () => {
    const setLocale = useSetLocale();
    const setTheme = useSetTheme();

    try {
        if (!('ExternalVendorJsInterface' in window)) return false;
        // @ts-ignore
        setLocale(window.ExternalVendorJsInterface.getLocale());
        // @ts-ignore
        setTheme(window.ExternalVendorJsInterface.isThemeLight() === 'true' ? 'light' : 'dark');
    } catch (e) {
        // @ts-ignore
        throw new Error(e.message);
    }
};
