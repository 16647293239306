import React from 'react';
import { Oval as LibraryLoader, OvalProps } from 'react-loader-spinner';

export const Loader = ({
    color = '#F11F89',
    secondaryColor = '#F11F89',
    height = 50,
    width = 50,
    ...rest
}: OvalProps) => (
    <LibraryLoader
        color={color}
        secondaryColor={secondaryColor}
        height={height}
        width={width}
        {...rest}
    />
);
