import { useEffect, useState } from 'react';

export const useOnScreen = (
    ref: React.RefObject<HTMLElement>,
    callback: () => void,
    options?: IntersectionObserverInit,
) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsVisible(entry.isIntersecting);

            if (entry.isIntersecting) {
                callback();
            }
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, options, callback]);

    return isVisible;
};
