import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { clientInstance, ticketsInstance } from 'src/api/instances';
import { Route } from 'src/constants';
import { useConvertedSearchData, useGetNextPresearchStep } from 'src/hooks';
import ErrorBoundary from 'src/providers/error-boundary';
import { getError, useSetError } from 'src/store/app';
import { useResetPrebookingData } from 'src/store/prebooking';
import { closeWebView } from 'src/utils';

import { Button } from '../market-ui/button';
import { Modal } from '../modal';

import styles from './index.module.scss';

export const ErrorHandling: FC = () => {
    const [isShowMessage, setIsShowMessage] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const resetPrebookingData = useResetPrebookingData();
    const queryClient = useQueryClient();
    const convertedSearchData = useConvertedSearchData();
    const nextPresearchStep = useGetNextPresearchStep(Route.TICKETS);

    const setError = useSetError();
    const error = getError();

    useEffect(() => {
        if (error.code) {
            // TODO: Logger output
            console.error('Error: ', error);
            setIsShowMessage(true);
        }
    }, [error]);

    ticketsInstance.interceptors.response.use((response) => {
        const {
            data: { resultCode, detailCode, details },
        } = response;

        if (resultCode === 'FAIL') {
            switch (detailCode) {
                case 401:
                    setError({ code: '0001', message: details });
                    break;
                case 500:
                    setError({ code: '0001', message: details });
                    break;
                case 400:
                    setError({ code: '0002', message: details });
                    break;
                case 600:
                    setError({ code: '0002', message: details });
                    break;
                default:
                    break;
            }
        }

        return response;
    });

    clientInstance.interceptors.response.use((response) => {
        const { error } = response.data;

        if (error) {
            setError({ code: error.code, message: error.message });
        }

        return response;
    });

    const handleOnClick = () => {
        switch (error.code) {
            case '0000':
                closeWebView();
                break;
            case '0001':
                closeWebView();
                break;
            case '0002':
                setError({ code: null, message: null });
                setIsShowMessage(false);
                break;
            case '0003':
                setError({ code: null, message: null });
                resetPrebookingData();
                setIsShowMessage(false);
                queryClient.invalidateQueries({ queryKey: ['getTickets', convertedSearchData] });
                navigate(nextPresearchStep);
        }
    };

    const renderErrorMessage = () => {
        switch (error.code) {
            case '0000':
                return t('shared.error.indentMessage');
            case '0001':
                return t('shared.error.message');
            case '0002':
                return error.message;
            case '0003':
                return t('shared.error.prebookingStale');
        }
    };

    const renderButtonText = () => {
        switch (error.code) {
            case '0000':
                return t('shared.error.backToMain');
            case '0001':
                return t('shared.error.backToMain');
            case '0002':
                return t('shared.error.close');
            case '0003':
                return t('shared.error.renew');
        }
    };

    const renderHeaderTitle = () => {
        switch (error.code) {
            case '0003':
                return t('shared.error.renewTitle');
            default:
                return null;
        }
    };

    return (
        <ErrorBoundary>
            <Outlet />
            <Modal
                className={styles.modal}
                position='center'
                width={280}
                height={140}
                header={renderHeaderTitle()}
                isOpen={isShowMessage}
                footer={
                    <Button onClick={handleOnClick} className={styles.back_button}>
                        {renderButtonText()}
                    </Button>
                }
            >
                <div className={styles.text}>{renderErrorMessage()}</div>
            </Modal>
        </ErrorBoundary>
    );
};
