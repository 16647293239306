import { useEffect } from 'react';
import { checkIsKeyboardShowed } from 'src/utils';

export const useSetOnKeyboardHandler = (cb: (isKeyboardShowed: boolean) => void) => {
    const handleViewportResize = () => {
        cb(!checkIsKeyboardShowed());
    };

    useEffect(() => {
        window.addEventListener('resize', handleViewportResize);

        return () => window.removeEventListener('resize', handleViewportResize);
    }, []);
};
