import { MutableRefObject, useEffect, useState } from 'react';

export const useElementScrollState = (elementRef: MutableRefObject<HTMLElement>) => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            setIsScrolled(elementRef.current.scrollTop > 0);
        };

        if (elementRef.current) {
            elementRef.current.addEventListener('scroll', onScroll);
        }

        return () => {
            if (elementRef.current) {
                elementRef.current.removeEventListener('scroll', onScroll);
            }
        };
    }, [elementRef.current]);

    return isScrolled;
};
