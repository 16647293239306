import { PrebookingCallbacksStore, PrebookingStore } from 'src/types';
import { create } from 'zustand';

const initialState: PrebookingStore = {
    phone: null,
    email: null,
    passengers: [],
    isAgreed: false,
    errors: {
        phone: null,
        email: null,
        passengers: [],
    },
};

export const prebookingStore = create<PrebookingStore & PrebookingCallbacksStore>()((set) => ({
    ...initialState,
    setPhone: (phone) => set(() => ({ phone: phone })),
    setEmail: (email) => set(() => ({ email: email })),
    setPassengers: (passengers) => set(() => ({ passengers: passengers })),
    setPassenger: (passenger) => set((state) => {
        const passengerIndex = state.passengers.findIndex(({ id }) => id === passenger.id);

        if (passengerIndex >= 0) {
            const newPassengers = [...state.passengers];

            newPassengers[passengerIndex] = passenger;

            return { passengers: newPassengers };
        }

        return { passengers: [...state.passengers, passenger] };
    }),
    setIsAgreed: (isAgreed) => set(() => ({ isAgreed: isAgreed })),
    setError: (fieldName, error) =>
        set((state) => ({ errors: { ...state.errors, [fieldName]: error } })),
    resetPrebookingData: () => set(() => initialState),
}));

export const getPassengers = () => prebookingStore((state) => state.passengers);
export const getPrebookingData = () => prebookingStore(({ passengers, phone, email, isAgreed }) => ({ passengers, phone, email, isAgreed }));
export const getErrors = () => prebookingStore((state) => state.errors);

export const useSetPhone = () => prebookingStore((state) => state.setPhone);
export const useSetEmail = () => prebookingStore((state) => state.setEmail);
export const useSetPassenger = () => prebookingStore((state) => state.setPassenger);
export const useSetPassengers = () => prebookingStore((state) => state.setPassengers);
export const useSetError = () => prebookingStore((state) => state.setError);
export const useSetIsAgreed = () => prebookingStore((state) => state.setIsAgreed);
export const useResetPrebookingData = () => prebookingStore((state) => state.resetPrebookingData);
