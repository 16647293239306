export enum QueryParam {
    DIRECTION = 'direction',
    WAY_IDS = 'way_ids',
    ORDER_TYPE = 'order_type',
    FAQ_TYPE = 'faq_type',
    AGE_TYPE = 'age_type',
}

export enum Param {
    TICKET_ID = 'ticket_id',
    PASSENGER_ID = 'passenger_id',
    DOC_NUMBER = 'doc_number',
    ORDER_NUMBER = 'order_number',
}

export enum Route {
    MAIN = '/',
    CONFIRMATION = '/confirmation',
    CONFIRMATION_ADD = '/confirmation-add',
    SEARCH = '/search',
    SEARCH_DIRECTION = '/search/direction',
    SEARCH_DATES = '/search/dates',
    SEARCH_DETAILS = '/search/details',
    TICKETS = '/tickets',
    TICKET_DETAILS = '/ticket-details',
    PREBOOKING = '/prebooking',
    PREBOOKING_PASSENGER = '/passenger',
    PREBOOKING_FAQ = '/faq',
    BOOKING = '/booking',
    BOOKING_PASSENGER = '/booking/passenger',
    FAQ = '/faq',
    PROFILE = '/profile',
    HISTORY = '/profile/history',
    PASSENGERS = '/profile/passengers',
    PASSENGERS_FORM_VIEW = '/profile/passengers/view',
    PASSENGERS_FORM_ADD = '/profile/passengers/add',
    TICKET_VIEW = '/ticket-view',
    ACTIVE_TICKET = '/active-ticket',
    BOOKED_TICKET = '/booked-ticket',
}
