import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'web-streams-polyfill';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/ru';
import 'intl/locale-data/jsonp/ky';
import 'url-polyfill';
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';

if (!('ReadableStream' in window)) {
    console.warn('ReadableStream не поддерживается в этом браузере.');
}
