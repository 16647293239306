import { ReactNode } from 'react';

export type ContextType = {
    setHeaderTitle: (value: string) => void;
    setHeaderChildren?: (children: ReactNode) => void;
};

export type Dictionary = {
    code: string;
    name: string;
};

export type GenderOptions = {
    id: Gender;
    value: string;
};

export type Gender = 'MR' | 'MRS';

export enum DocumentType {
    NP = 'NP',
    PSP = 'PSP',
    SR = 'SR',
}

export type SelectOption = {
    id: string;
    value: string;
};

export type AgeType = 'ADT' | 'CHD' | 'INF';

export type WeightType = 'KG' | 'PC';

export enum FlightType {
    INTERNAL_FLIGHT = 'inner',
    OUTER_FLIGHT = 'outer',
}

export enum OrderType {
    ACTIVE = 'active',
    BOOKED = 'booked',
    PASSED = 'passed',
}

export enum FaqType {
    MAIN = 'main',
    ADVICES = 'advices',
}

enum ResponseStatus {
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
}

export type Response<T> = {
    result: T;
    resultCode: ResponseStatus;
    details: string;
    detailCode: number;
};
