import { INTERNAL_WAY_CODE } from 'src/constants';
import { Directions, FlightType } from 'src/types';

export const getFlightDirection = (directions: Directions) => {
    const isDirectionInternal =
        INTERNAL_WAY_CODE.includes(directions.to?.code) &&
        INTERNAL_WAY_CODE.includes(directions.from?.code);

    const flightDirection = isDirectionInternal
        ? FlightType.INTERNAL_FLIGHT
        : FlightType.OUTER_FLIGHT;

    return { flightDirection, isDirectionInternal };
};
