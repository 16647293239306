import {
    BaggagePaid,
    BestSeats,
    Business,
    Cancel,
    EditTicket,
    EditTicketPaid,
    LuggageDetails,
    MealFree,
    MealPaid,
    MealSpecial,
    NoReturnTicket,
    ReturnTicket,
    TicketChange,
    Timer,
    Vip,
} from 'src/assets/icons';

export const featureToIcon: Record<string, string> = {
    'meal-free': MealFree,
    baggage: BaggagePaid,
    luggage: LuggageDetails,
    'edit-ticket-paid': EditTicketPaid,
    'baggage-paid': BaggagePaid,
    'no-return-ticket': NoReturnTicket,
    'meal-special': MealSpecial,
    'edit-ticket': EditTicket,
    'return-ticket': ReturnTicket,
    'best-seats': BestSeats,
    business: Business,
    timer: Timer,
    vip: Vip,
    'meal-paid': MealPaid,
    'paid-ticket-change': TicketChange,
    cancel: Cancel,
    'no-edit-ticket': EditTicketPaid,
    'ticket-change': TicketChange,
};
